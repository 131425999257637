@if (mainTitle) {
<h5 class="headline"
  (click)="method ? method() : null">
  <span class="headline-title">{{mainTitle | translit}}</span>
  <p class="headline-subtitle">{{subtitle | translit}}</p>
</h5>
}

<div class="scroll-container"
  [ngClass]="name">
  <ng-content />
</div>